const mobileBannerSelector = document.getElementById(
   'js-banner-mobile-selector'
)

if (mobileBannerSelector) {
   new Vue({
      el: mobileBannerSelector,
      data() {
         return {
            latest: [],
            loading: false,
            addressSuggestions: [],
            areas: [],
            areasSelected: [],
            city: '',
            citiesSelected: [],
            citySuggestions: [],
            citySuggestionsVisible: false,
            searchTimeout: null,
            tags: [],
         }
      },
      methods: {
         getTags() {
            return fetch('/Umbraco/Api/Case/Tags', {
               method: 'GET',
            })
               .then((response) => response.json())
               .then((json) => {
                  this.tags = json
               })
         },

         getAreas() {
            return fetch('/Umbraco/Api/Case/Areas', {
               method: 'GET',
            })
               .then((response) => response.json())
               .then((json) => {
                  this.areas = json
               })
         },

         debounceCitySearch() {
            this.loading = true

            if (this.searchTimeout) {
               clearTimeout(this.searchTimeout)
            }

            this.searchTimeout = setTimeout(() => {
               this.citySearch()
               this.handleSearch()
            }, 500)
         },

         handleCitySearch() {
            if (this.city == '') {
               this.citySuggestionsVisible = false
            } else {
               this.citySuggestionsVisible = true
               this.debounceCitySearch()
            }
         },

         citySearch() {
            fetch('https://dawa.aws.dk/postnumre/autocomplete?q=' + this.city)
               .then((response) => response.json())
               .then((data) => {
                  if (data.length) {
                     this.citySuggestions = data
                  }
               })
         },

         clearLatest() {
            window.localStorage.removeItem('latest')
            this.latest = []
         },

         submit(obj, type) {
            const url = new URL(window.location.origin + '/til-leje')

            console.log(obj)

            let ZipCodes = []
            let TagIds = []

            if (type == 'area') {
               url.search = new URLSearchParams({
                  ZipCodes: obj.Cities.map((c) => c.ZipCode),
               })
               this.latest.push({
                  name: obj.Name,
                  value: url.href,
               })
            } else if (type == 'city') {
               url.search = new URLSearchParams({
                  ZipCodes: [obj.postnummer.nr],
               })
               this.latest.push({
                  name: obj.postnummer.navn,
                  value: url.href,
               })
            } else if (type == 'tag') {
               url.search = new URLSearchParams({
                  TagIds: [obj.Id],
               })
               this.latest.push({
                  name: obj.Name,
                  value: url.href,
               })
            } else if (type == 'address') {
               url.search = new URLSearchParams({
                  AddressQuery: obj.Text,
               })
               this.latest.push({
                  name: obj.Text,
                  value: url.href,
               })
            }

            window.localStorage.setItem('latest', JSON.stringify(this.latest))

            window.location.href = url.href
         },

         handleSearch() {
            fetch('/Umbraco/Api/Case/Search', {
               method: 'POST',
               headers: {
                  'Content-Type': 'application/json',
               },
               body: JSON.stringify({
                  AddressQuery: this.city,
               }),
            })
               .then((response) => response.json())
               .then((json) => {
                  this.addressSuggestions = json.Suggestions
               })
               .catch((e) => {
                  console.log('catch', e)
               })
               .finally(() => {
                  this.loading = false
               })
         },
      },
      computed: {
         filteredAreaSuggestions() {
            return this.areas.filter(
               (x) =>
                  !this.areasSelected.find((y) => y.Id == x.Id) &&
                  x.Name.toLowerCase().includes(this.city.toLowerCase())
            )
         },
         filteredCitySuggestions() {
            return this.citySuggestions.filter(
               (x) => !this.citiesSelected.find((y) => y.zip == x.postnummer.nr)
            )
         },
         filteredTagSuggestions() {
            return this.tags.filter((x) =>
               x.Name.toLowerCase().includes(this.city.toLowerCase())
            )
         },
      },
      mounted() {
         if (window.localStorage.getItem('latest')) {
            this.latest = JSON.parse(window.localStorage.getItem('latest'))
         }

         const targets = document.getElementsByClassName(
            'js-banner-city-dropdown'
         )

         document.addEventListener(
            'click',
            (event) => {
               const path = event.composedPath()
               let isAnyInPath = false

               for (let i = 0; i < targets.length; i++) {
                  if (path.includes(targets[i])) {
                     isAnyInPath = true
                  }
               }

               if (!isAnyInPath) {
                  this.citySuggestionsVisible = false
               }
            },
            { passive: true }
         )

         this.getTags()
         this.getAreas()
      },
   })
}
