const popup = document.getElementById('js-popup');
if(popup) {
    const popupClose = document.getElementById('js-popup-close');
    popupClose.addEventListener('click', () => {
        popup.classList.add('modal--hidden');
    });

    setTimeout(() => {
        popup.classList.remove('modal--hidden');
    }, 3000)
}