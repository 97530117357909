const selectedCasesSliders = document.querySelectorAll('.js-selected-cases-slider')

if (selectedCasesSliders) {
   selectedCasesSliders.forEach((slider) => {
      const flkty = new Flickity(slider, {
         cellAlign: 'left',
         wrapAround: false,
         groupCells: false,
         imagesLoaded: true,
         prevNextButtons: false,
         pageDots: false,
         watchCSS: true,
      })
   })
}

const selectedCasesLists = document.getElementsByClassName('js-selected-cases-list')

if (selectedCasesLists.length) {
   for (let i = 0; i < selectedCasesLists.length; i++) {
      const el = selectedCasesLists[i]

      const appartments = JSON.parse(el.querySelector('.js-selected-cases-list-data').innerHTML);
      const sortings = JSON.parse(el.querySelector('.js-selected-cases-list-sortings').innerHTML);

      
      new Vue({
         el,
         data() {
            return {
               appartments,
               sortings,
               sorting: "Address",
               direction: "desc",
               open: false,
               limit: 15
            }
         },
         computed: {
            showMore() {
               return this.appartments.length > this.limit + 5
            }
         },
         methods: {
            setSorting(sort) {
               if (this.sorting === sort) {
                  this.direction = this.direction === 'asc' ? 'desc' : 'asc'
               } else {
                  this.sorting = sort
                  this.direction = 'desc'
               }

               this.appartments.sort((a, b) => {
                  if (this.direction === 'asc') {
                     return a[this.sorting] > b[this.sorting] ? 1 : -1
                  } else {
                     return a[this.sorting] < b[this.sorting] ? 1 : -1
                  }
               });
            },
            formatDate(date) {
               const d = new Date(date)

               const months = [
                  'Januar',
                  'Februar',
                  'Marts',
                  'April',
                  'Maj',
                  'Juni',
                  'Juli',
                  'August',
                  'September',
                  'Oktober',
                  'November',
                  'December',
               ];

               return d.getDate() + '. ' + months[d.getMonth()].toLowerCase() + ' ' + d.getFullYear()
            },
            formatPrice(price) {
               return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
            },
         }
      });
   }
}
