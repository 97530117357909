const dropdowns = document.getElementsByClassName('js-dropdown')

if (dropdowns.length) {
   for (let i = 0; i < dropdowns.length; i++) {
      const dropdown = dropdowns[i]
      const trigger = dropdown.querySelector('.dropdown__trigger')
      const tags = dropdown.querySelector('.dropdown__tags')
      const content = dropdown.querySelector('.dropdown__content')

      trigger.addEventListener('click', () => {
         if (tags) {
            tags.classList.toggle('dropdown__tags--active')
         }
         if (content) {
            content.classList.toggle('dropdown__content--active')
         }

         trigger.classList.toggle('dropdown__trigger--active')
      })
   }
}
