document.addEventListener('DOMContentLoaded', function () {
   const gridElements = document.querySelectorAll('.js-grid-editor')

   if (gridElements.length) {
      gridElements.forEach((element) => {
         const elementWatcher = scrollMonitor.create(element, 0)

         elementWatcher.enterViewport(function () {
            element.classList.remove('grid-editor--top')
            element.classList.remove('grid-editor--bottom')
         })

         elementWatcher.exitViewport(function () {
            if (elementWatcher.isAboveViewport) {
               element.classList.add('grid-editor--top')
            } else if (elementWatcher.isBelowViewport) {
               element.classList.add('grid-editor--bottom')
            }
         })

         if (!elementWatcher.isInViewport) {
            if (elementWatcher.isAboveViewport) {
               element.classList.add('grid-editor--top')
            } else if (elementWatcher.isBelowViewport) {
               element.classList.add('grid-editor--bottom')
            }
         }
      })
   }
})
