const contactEditors = document.getElementsByClassName('js-contact-form')

if (contactEditors.length) {
   for (let i = 0; i < contactEditors.length; i++) {
      const el = contactEditors[i]


      const redirectLink = el.parentNode.getAttribute('data-after-link')

      new Vue({
         el,
         data() {
            return {
               isOpen: false,
               name: '',
               companyName: '',
               phone: '',
               email: '',
               comment: '',
               reference: '',
               accepted: false,
               loading: false,
               success: false,
               department: ''
            }
         },
         methods: {
            open(general) {
               if (general) {
                  console.log('general')
                  document.getElementById("js-general-contact").classList.remove("modal--hidden")
               } else {
                  this.isOpen = true
               }
               document
                  .getElementsByTagName('body')[0]
                  .classList.add('modal-open')
            },
            close() {
               this.isOpen = false
               document
                  .getElementsByTagName('body')[0]
                  .classList.remove('modal-open')

               this.success = false
               this.accepted = false
               this.loading = false
            },
            submit(e) {
               e.preventDefault()

               this.loading = true

               let subject = window.location.href
               let address = document.querySelector('#js-case-view')
               if (address) {
                  address = address.getAttribute('data-address')
                  subject = address
               }

               const toEmail = this.department || el.getAttribute('data-email')

               const mailContent = {
                  toEmail,
                  replyTo: this.email,
                  subject: 'Henvendelse fra ' + subject,
                  fields: {
                     Name: this.name,
                     CompanyName: this.companyName,
                     Phone: this.phone,
                     Email: this.email,
                     Comment: this.comment,
                     Reference: this.reference,
                     Address: address ? address.innerText : '',
                     Link: window.location.href,
                  },
               }

               fetch('/Umbraco/Api/Contact/Contact', {
                  method: 'POST',
                  headers: {
                     'Content-Type': 'application/json',
                  },
                  body: JSON.stringify(mailContent),
               })
                  .then((response) => response.json())
                  .then(() => {
                     const caseElement = document.getElementById('js-case-view')
                     if (caseElement) {
                        const caseId = caseElement.getAttribute('data-id')
                        if (caseId) {
                           fetch(
                              '/Umbraco/Api/Case/Track?id=' +
                                 caseId +
                                 '&type=inquired'
                           ).then(() => {
                              if (redirectLink) {
                                 window.location.href = redirectLink
                              }
                           })
                        }
                     }

                     this.success = true
                     this.loading = false
                  })
                  .finally(() => {
                     this.loading = false
                  })
            },
         },
      })
   }
}
