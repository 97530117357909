const videoCtaEditors = document.getElementsByClassName('js-video-cta-editor')

if (videoCtaEditors.length) {
   for (let i = 0; i < videoCtaEditors.length; i++) {
      const editor = videoCtaEditors[i]
      const playButton = editor.querySelector('.js-video-cta-editor-play')
      const iframe = editor.querySelector('.js-video-cta-editor-iframe')
      const backdrop = editor.querySelector('.js-video-cta-editor-backdrop')

      playButton.addEventListener('click', () => {
         iframe.classList.add('video-cta-editor__iframe--active')
         document.getElementsByTagName('body')[0].classList.add('modal-open')
      })

      backdrop.addEventListener('click', () => {
         iframe.classList.remove('video-cta-editor__iframe--active')
         document.getElementsByTagName('body')[0].classList.remove('modal-open')
      })
   }
}
