const archive = document.getElementById('js-archive-view')

if (archive) {
   new Vue({
      el: archive,
      data() {
         return {
            initial: true,
            step: 1,
            validated: [],
            email: '',
            passwordRepeat: '',
            facilities: [],
            areas: [],
            unitCaseTypes: [],
            loading: false,
            error: '',
            showAllCities: false,

            facilityIcons: null,
            unitTypeIcons: null,
            cityImages: null,

            appartments: [],
            acceptTerms: false,

            signup: {
               firstname: '',
               lastname: '',
               phoneNumber: '',
               email: '',
               password: '',
               maxRent: null,
               minSize: null,
               minRooms: null,
               acquisitionDate: null,
               customAreaIds: [],
               unitCaseTypeIds: [],
               facilityIds: [],
               allowMailAndPhoneContact: false,
            },
         }
      },
      methods: {
         formatPrice(price) {
            return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
         },

         formatDate(date) {
            const dateObj = new Date(date)
            if(dateObj > new Date()) {
               return dateObj.toLocaleDateString('da-DK', 
               {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
               });
            } else {
               return 'Ledig nu'
            }
         },

         submit() {
            this.loading = true

            this.signup.acquisitionDate = new Date(this.signup.acquisitionDate)

            fetch('/Umbraco/Api/Case/SignUp', {
               method: 'POST',
               headers: {
                  'Content-Type': 'application/json',
               },
               body: JSON.stringify(this.signup),
            })
               .then((response) => {
                  if (response.status === 200) {
                     response.json().then((json) => {
                        if (json) {
                           this.error = json
                        } else {
                           window.location.href =
                              archive.getAttribute('data-redirect-link')
                        }
                     })
                  } else {
                     response.json().then((json) => {
                        this.error = json.Message
                     })
                  }
               })
               .catch((e) => {
                  console.log('catch', e)
                  this.error = e.Message
               })
               .finally(() => {
                  this.loading = false
               })
         },
         next() {
            if (!this.validated.includes(this.step)) {
               this.validated.push(this.step)
            }

            const errors = document.getElementsByClassName(
               'archive-view__section-error--active'
            )

            if (!errors.length) {
               const isDesktop = window.innerWidth > 985

               if (isDesktop ? this.step === 3 : this.step === 5) {
                  this.submit()
               } else {
                  this.step = this.step + 1

                  this.checkValidation()
               }
            } else {
               errors[0].focus()
            }
         },
         checkValidation() {
            if (this.validated.includes(this.step)) {
               this.validated.splice(this.validated.indexOf(this.step), 1)
            }
         },

         getImagesInOrder(images) {
            return [...images].sort((a, b) => a.DisplayOrder - b.DisplayOrder)
         },

         goTo(step) {
            if (step < this.step) {
               this.step = step
               this.checkValidation()
            }
         },
         setCity(name, zipCode) {
            this.signup.city = { name, zipCode }
         },

         sectionActive(section) {
            const isDesktop = window.innerWidth > 985

            if (section == 'final') {
               return isDesktop ? this.step === 4 : this.step === 6
            } else if (section == 'information') {
               return isDesktop ? this.step === 3 : this.step === 5
            } else if (section == 'facilities') {
               return isDesktop ? this.step === 2 : this.step === 4
            } else if (section == 'appartment') {
               return isDesktop ? this.step === 1 : this.step === 3
            } else if (section == 'types') {
               return isDesktop ? this.step === 1 : this.step === 2
            } else if (section == 'cities') {
               return this.step === 1
            } else if (section == 'next') {
               return isDesktop ? this.step !== 4 : this.step !== 6
            }
         },

         getFacilities() {
            fetch('/Umbraco/Api/Case/Facilities', {
               method: 'GET',
            })
               .then((response) => response.json())
               .then((json) => {
                  this.facilities = json
               })
         },

         getAreas() {
            fetch('/Umbraco/Api/Case/Areas', {
               method: 'GET',
            })
               .then((response) => response.json())
               .then((json) => {
                  this.areas = json
               })
         },

         getUnitCaseTypes() {
            fetch('/Umbraco/Api/Case/UnitCaseTypes', {
               method: 'GET',
            })
               .then((response) => response.json())
               .then((json) => {
                  this.unitCaseTypes = json
               })
         },
         selectArea(id) {
            arrayToggle(this.signup.customAreaIds, id)
         },
         selectFacility(id) {
            arrayToggle(this.signup.facilityIds, id)
         },
         selectType(id) {
            arrayToggle(this.signup.unitCaseTypeIds, id)
         },

         handleSearch() {
            let newZips = []

            if (this.signup.customAreaIds.length) {
               this.signup.customAreaIds.forEach((x) => {
                  const area = this.areas.find((y) => y.Id == x)

                  area.Cities.forEach((y) => {
                     newZips.push(y.ZipCode.toString())
                  })
               })
            }

            let searchObj = JSON.parse(
               JSON.stringify({
                  ZipCodes: newZips,
                  TypeIds: this.signup.unitCaseTypeIds,
                  FacilityIds: this.signup.facilityIds,
                  MaxRent: this.signup.maxRent,
                  MinSize: this.signup.minSize,
                  MinRooms: this.signup.minRooms,
                  AcquisitionDate: null,
               })
            )

            if (this.signup.acquisitionDate) {
               searchObj.AcquisitionDate = new Date(
                  this.signup.acquisitionDate
               ).toISOString()
            }

            fetch('/Umbraco/Api/Case/Search', {
               method: 'POST',
               headers: {
                  'Content-Type': 'application/json',
               },
               body: JSON.stringify(searchObj),
            })
               .then((response) => response.json())
               .then((json) => {
                  this.appartments = json.Cases
                  this.initial = false
               })
               .catch((e) => {
                  console.log('catch', e)
               })
         },

         debounceSearch() {
            if (this.searchTimeout) {
               clearTimeout(this.searchTimeout)
            }
            if (window.innerWidth > 985) {
               this.searchTimeout = setTimeout(() => {
                  this.handleSearch()
               }, 500)
            }
         },
      },
      mounted() {
         this.getFacilities()
         this.getAreas()
         this.getUnitCaseTypes()

         this.facilityIcons = JSON.parse(
            archive.getAttribute('data-facility-icons')
         )
         this.unitTypeIcons = JSON.parse(
            archive.getAttribute('data-unittype-icons')
         )
         this.cityImages = JSON.parse(archive.getAttribute('data-city-images'))
      },
      watch: {
         signup: {
            handler: function () {
               this.debounceSearch()
            },
            deep: true,
         },
      },
   })
}
