// Image fallback for shit browsers

function support_format_webp() {
   const elem = document.createElement('canvas')
   if (!!(elem.getContext && elem.getContext('2d'))) {
      return elem.toDataURL('image/webp').indexOf('data:image/webp') == 0
   } else {
      return false
   }
}

document.addEventListener('DOMContentLoaded', () => {
   if (!support_format_webp()) {
      const images = document.querySelectorAll('img')
      images.forEach((img) => {
         img.setAttribute(
            'src',
            img.getAttribute('src').replace('&format=webp', '&format=png')
         )
      })

      const svgImages = document.querySelectorAll('image')
      svgImages.forEach((img) => {
         img.setAttribute(
            'xlink:href',
            img
               .getAttribute('xlink:href')
               .replace('&format=webp', '&format=png')
         )
      })
   }
})
