const rentals = document.getElementById('js-rentals-view')

const styles = [
   {
      featureType: 'all',
      elementType: 'labels',
      stylers: [
         {
            gamma: 0.26,
         },
         {
            visibility: 'off',
         },
      ],
   },
   {
      featureType: 'administrative.province',
      elementType: 'all',
      stylers: [
         {
            visibility: 'on',
         },
         {
            lightness: -50,
         },
      ],
   },
   {
      featureType: 'administrative.province',
      elementType: 'labels.text',
      stylers: [
         {
            lightness: 20,
         },
      ],
   },
   {
      featureType: 'administrative.province',
      elementType: 'labels.text.stroke',
      stylers: [
         {
            visibility: 'off',
         },
      ],
   },
   {
      featureType: 'landscape',
      elementType: 'all',
      stylers: [
         {
            visibility: 'on',
         },
         {
            color: '#f3f4f4',
         },
      ],
   },
   {
      featureType: 'poi',
      elementType: 'all',
      stylers: [
         {
            visibility: 'off',
         },
      ],
   },
   {
      featureType: 'poi.park',
      elementType: 'geometry.fill',
      stylers: [
         {
            visibility: 'on',
         },
         {
            color: '#83cead',
         },
      ],
   },
   {
      featureType: 'road',
      elementType: 'all',
      stylers: [
         {
            hue: '#ffffff',
         },
      ],
   },
   {
      featureType: 'road',
      elementType: 'labels.text',
      stylers: [
         {
            visibility: 'on',
         },
      ],
   },
   {
      featureType: 'road.highway',
      elementType: 'geometry',
      stylers: [
         {
            lightness: 50,
         },
         {
            hue: '#ffffff',
         },
      ],
   },
   {
      featureType: 'road.arterial',
      elementType: 'geometry',
      stylers: [
         {
            lightness: 20,
         },
      ],
   },
   {
      featureType: 'road.highway',
      elementType: 'geometry',
      stylers: [
         {
            visibility: 'on',
         },
         {
            color: '#fee379',
         },
      ],
   },
]

const mapOptions = {
   disableDefaultUI: true,
   zoomControl: true,
   maxZoom: 14,
   styles,
}



if (rentals) {
   const url = new URL(window.location.href);
   const freeNow = url.searchParams.get('FreeNow') === 'true';

   const initialSearch = {
      ZipCodes: [],
      TypeIds: [],
      TagIds: [],
      RentalPeriod: '',
      FacilityIds: [],
      MaxRent: null,
      MinSize: null,
      MinRooms: '0',
      MaxRooms: '0',
      AcquisitionDateFrom: null,
      AcquisitionDateTo: freeNow ? new Date().toISOString().split('T')[0] : null,
      PageIndex: 1,
      PageSize: 20,
      AddressQuery: '',
   }

   const app = new Vue({
      el: rentals,
      data() {
         return {
            addressSuggestions: [],
            city: '',
            citySuggestions: [],
            citySuggestionsVisible: false,
            citiesSelected: [],
            openSearch: false,
            openFilter: false,
            fixButtons: false,
            fixHelp: false,
            helpHeight: 0,
            loading: true,
            citySearchTimeout: null,
            searchTimeout: null,
            addressTimeout: null,
            appartments: [],
            areas: [],
            areasSelected: [],
            tags: [],
            tagsSelected: [],
            facilities: [],
            unitCaseTypes: [],
            search: {
               ...initialSearch,
            },

            freeNow,

            mode: 'list',
            map: null,
            markers: [],
            infowindow: null,

            prevPage: 0,
            hasMorePages: false,

            featureds: null,
            featuredLoading: true,
            featuredFlkty: null,
         }
      },
      methods: {
         formatPrice(price) {
            return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
         },
         getFeatured() {
            this.featuredLoading = true;
            
            const selectedAreaIds = this.areasSelected.map((x) =>
               x.Id.toString()
            )

            const zipCodes = this.citiesSelected.map((x) => x.zip)

            const areaZipCodes = this.areas.map((x) => ({
               ...x,
               ZipCodes: x.Cities.map((y) => y.ZipCode.toString()),
            }))

            const areasFromZipCodes = areaZipCodes.filter((x) =>
               x.ZipCodes.some((y) => zipCodes.includes(y))
            )

            const featuredSearch = {
               Areas: [
                  ...selectedAreaIds,
                  ...areasFromZipCodes.map((x) => x.Id),
               ],
               FeaturedCases: JSON.parse(rentals.getAttribute('data-featured')),
            }

            fetch('/Umbraco/Api/Case/Featured', {
               method: 'POST',
               headers: {
                  'Content-Type': 'application/json',
               },
               body: JSON.stringify(featuredSearch),
            })
               .then((response) => response.json())
               .then((json) => {
                  this.featuredLoading = false;
                  
                  this.featureds = json

                  if (this.featuredFlkty) {
                     this.featuredFlkty.forEach(x => x.destroy());
                  }

                  setTimeout(() => {
                     this.featuredFlkty = [];
                     document.querySelectorAll('.js-featured-element-slider').forEach((element) => {
                        const flkty = new Flickity(element,
                        {
                           cellAlign: 'left',
                           wrapAround: true,
                           groupCells: false,
                           imagesLoaded: true,
                           prevNextButtons: true,
                           pageDots: true,
                           autoPlay: true,
                           lazyLoad: true,
                           draggable: false,
                        }
                     );
                     this.featuredFlkty.push(flkty);
                  })

                  const flktyWrapper = new Flickity('.featured-element__wrapper',
                     {
                        cellAlign: 'left',
                        wrapAround: true,
                        groupCells: false,
                        prevNextButtons: false,
                        pageDots: true,
                        autoPlay: true,
                     }
                  );

                  this.featuredFlkty.push(flktyWrapper);
               })
            })
         },

         formatDate(date) {
            const dateObj = new Date(date)
            if(dateObj > new Date()) {
               return dateObj.toLocaleDateString('da-DK', 
               {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
               });
            } else {
               return 'Ledig nu'
            }
         },

         toggleSearch() {
            this.openSearch = !this.openSearch
         },

         toggleFilter() {
            if (this.openFilter) {
               document
                  .getElementsByTagName('body')[0]
                  .classList.remove('modal-open')
            } else {
               document
                  .getElementsByTagName('body')[0]
                  .classList.add('modal-open')
            }

            this.openFilter = !this.openFilter
         },
         reset() {
            this.citiesSelected = []
            this.areasSelected = []
            this.tagsSelected = []
            this.city = ''
            this.search = { ...initialSearch }
         },

         debounceCitySearch() {
            if (this.citySearchTimeout) {
               clearTimeout(this.citySearchTimeout)
            }

            this.citySearchTimeout = setTimeout(() => {
               this.citySearch()
            }, 500)
         },

         handleCitySearch() {
            this.search.AddressQuery = this.city

            if (this.city == '') {
               this.citySuggestionsVisible = false
            } else {
               this.citySuggestionsVisible = true
               this.debounceCitySearch()
            }
         },

         addTag(tag) {
            this.tagsSelected.push(tag)
            this.citySuggestionsVisible = false
            this.city = ''
            this.search.AddressQuery = ''
         },

         removeTag(id) {
            this.tagsSelected = [...this.tagsSelected].filter((x) => x.Id != id)
         },

         addArea(area) {
            this.areasSelected.push(area)
            this.citySuggestionsVisible = false
            this.city = ''
            this.search.AddressQuery = ''
         },

         removeArea(id) {
            this.areasSelected = [...this.areasSelected].filter(
               (x) => x.Id != id
            )
         },

         addCity(suggestion) {
            this.citiesSelected.push({
               name: suggestion.navn,
               zip: suggestion.nr,
            })
            this.citySuggestionsVisible = false
            this.city = ''
            this.search.AddressQuery = ''
         },

         removeCity(zip) {
            this.citiesSelected = [...this.citiesSelected].filter(
               (x) => x.zip != zip
            )
         },

         setAddressQuery(address) {
            this.city = address.Text
            this.search.AddressQuery = address.Text
         },

         citySearch() {
            fetch(
               'https://dawa.aws.dk/postnumre/autocomplete?per_side=100&q=' +
                  this.city
            )
               .then((response) => response.json())
               .then((data) => {
                  if (data) {
                     this.citySuggestions = data
                        .sort((a, b) => a.tekst.localeCompare(b.tekst))
                        .slice(0, 20)
                  }
               })
         },

         getTags() {
            return fetch('/Umbraco/Api/Case/Tags', {
               method: 'GET',
            }).then((response) => response.json())
         },

         getAreas() {
            return fetch('/Umbraco/Api/Case/Areas', {
               method: 'GET',
            }).then((response) => response.json())
         },

         getTagsAndAreas() {
            return Promise.all([this.getTags(), this.getAreas()])
         },

         handleInitialSearch() {
            const url = new URL(window.location.href)

            const urlParams = new URLSearchParams(url.search)
            const params = Object.fromEntries(urlParams)

            this.getTagsAndAreas().then(([tags, areas]) => {
               this.tags = tags
               this.areas = areas

               Object.keys(params).forEach((key) => {
                  if (
                     key == 'ZipCodes' ||
                     key == 'TypeIds' ||
                     key == 'TagIds' ||
                     key == 'FacilityIds'
                  ) {
                     if (key == 'ZipCodes') {
                        let zips = params[key].split(',')

                        areas.forEach((x) => {
                           if (
                              x.Cities.every((c) =>
                                 zips.includes(c.ZipCode.toString())
                              )
                           ) {
                              this.addArea(x)

                              zips = zips.filter(
                                 (z) =>
                                    !x.Cities.find(
                                       (c) => c.ZipCode.toString() == z
                                    )
                              )
                           }
                        })

                        zips.forEach((zip) => {
                           if (!this.citiesSelected.find((x) => x.zip == zip)) {
                              this.citiesSelected.push({ zip })
                           }
                        })
                     } else if (key == 'TagIds') {
                        params[key] = params[key].split(',')

                        params[key].forEach((tagId) => {
                           if (!this.tagsSelected.find((x) => x.Id == tagId)) {
                              console.log(this.tags.find((x) => x.Id == tagId))
                              this.tagsSelected.push(
                                 this.tags.find((x) => x.Id == tagId)
                              )
                           }
                        })
                     } else {
                        params[key] = params[key].split(',')
                     }
                  } else if (key == 'PageIndex') {
                     params.PageSize =
                        parseInt(params[key]) * initialSearch.PageSize

                     params[key] = parseInt(params[key])
                  }
               })

               if (params.AddressQuery !== undefined) {
                  this.city = params.AddressQuery
               }

               this.search = { ...this.search, ...params }
            })
         },

         handleSearch() {
            this.loading = true

            let searchObj = JSON.parse(JSON.stringify(this.search))

            if (this.search.AcquisitionDateFrom) {
               searchObj.AcquisitionDateFrom = new Date(
                  this.search.AcquisitionDateFrom
               ).toISOString().split('T')[0]
            }

            if (this.search.AcquisitionDateTo) {
               searchObj.AcquisitionDateTo = new Date(
                  this.search.AcquisitionDateTo
               ).toISOString().split('T')[0]
            }

            if(this.freeNow) {
               searchObj.AcquisitionDateTo = new Date().toISOString().split('T')[0]
               searchObj.AcquisitionDateFrom = null
            }

            let newZips = []

            if (this.areasSelected.length) {
               this.areasSelected.forEach((x) => {
                  x.Cities.forEach((y) => {
                     newZips.push(y.ZipCode.toString())
                  })
               })
            }

            if (this.tagsSelected.length) {
               searchObj.TagIds = this.tagsSelected.map((x) => x.Id)
            } else {
               searchObj.TagIds = []
            }

            if (this.citiesSelected.length) {
               this.citiesSelected.forEach((x) => {
                  newZips.push(x.zip.toString())
               })
            }

            newZips = [...new Set(newZips)]

            searchObj.ZipCodes = [...newZips]

            fetch('/Umbraco/Api/Case/Search', {
               method: 'POST',
               headers: {
                  'Content-Type': 'application/json',
               },
               body: JSON.stringify(searchObj),
            })
               .then((response) => response.json())
               .then((json) => {
                  if (this.prevPage !== this.search.PageIndex) {
                     this.appartments = [...this.appartments].concat(json.Cases)

                     this.prevPage = this.search.PageIndex
                  } else {
                     this.appartments = json.Cases
                  }

                  this.addressSuggestions = json.Suggestions

                  this.hasMorePages = json.HasMorePages
               })
               .catch((e) => {
                  console.log('catch', e)
               })
               .finally(() => {
                  if (this.hasMorePages && this.mode == 'map') {
                     this.getNextPage()
                  } else {
                     this.loading = false

                     if (history.pushState) {
                        const newUrl = new URL(window.location.href)

                        delete searchObj.PageSize

                        newUrl.search = new URLSearchParams(searchObj)

                        let newLink = newUrl.href

                        if (!searchObj.ZipCodes.length) {
                           newLink = newLink.replace('ZipCodes=', '')
                        }

                        if (!searchObj.TypeIds.length) {
                           newLink = newLink.replace('&TypeIds=', '')
                        }

                        if (!searchObj.TagIds.length) {
                           newLink = newLink.replace('&TagIds=', '')
                        }

                        if (!searchObj.RentalPeriod) {
                           newLink = newLink.replace('&RentalPeriod=', '')
                        }

                        if (!searchObj.FacilityIds.length) {
                           newLink = newLink.replace('&FacilityIds=', '')
                        }

                        if (!searchObj.MaxRent) {
                           newLink = newLink.replace('&MaxRent=null', '')
                        }

                        if (!searchObj.MinSize) {
                           newLink = newLink.replace('&MinSize=null', '')
                        }

                        if (!searchObj.MinRooms == '0') {
                           newLink = newLink.replace('&MinRooms=0', '')
                        }

                        if (searchObj.MaxRooms == '0') {
                           newLink = newLink.replace('&MaxRooms=0', '')
                        }

                        if (!searchObj.AddressQuery) {
                           newLink = newLink.replace('&AddressQuery=', '')
                        }

                        if(this.freeNow) {
                           if(!newLink.includes('&FreeNow=true')) {
                              newLink += '&FreeNow=true'
                           }
                        } else {
                           newLink = newLink.replace('&FreeNow=true', '')
                        } 
                        
                        if (!searchObj.AcquisitionDateFrom) {
                           newLink = newLink.replace(
                              '&AcquisitionDateFrom=null',
                              ''
                           )
                        } 

                        if (!searchObj.AcquisitionDateTo) {
                           newLink = newLink.replace(
                              '&AcquisitionDateTo=null',
                              ''
                           )
                        } 

                        if (newLink.endsWith('?')) {
                           newLink = newLink.replace('?', '')
                        }

                        window.history.replaceState(
                           { path: newLink },
                           '',
                           newLink
                        )
                     }
                  }

                  setTimeout(() => {
                     const prevCaseEl = document.getElementById(
                        `case-${window.localStorage.getItem('prevCaseId')}`
                     )

                     if (
                        prevCaseEl &&
                        window.localStorage.getItem('prevCaseId')
                     ) {
                        prevCaseEl.scrollIntoView({
                           behavior: 'smooth',
                           block: 'center',
                        })

                        window.localStorage.removeItem('prevCaseId')
                     }
                  })
               })
         },

         debounceSearch(skip) {
            if (this.searchTimeout) {
               clearTimeout(this.searchTimeout)
            }

            if (skip && this.appartments.length) {
               this.handleSearch()
            } else {
               this.searchTimeout = setTimeout(() => {
                  this.handleSearch()
                  this.getFeatured()
               }, 500)
            }
         },

         getNextPage() {
            this.search.PageIndex = parseInt(this.search.PageIndex) + 1
         },

         getImagesInOrder(images) {
            return [...images].sort((a, b) => a.DisplayOrder - b.DisplayOrder)
         },

         getFacilities() {
            fetch('/Umbraco/Api/Case/Facilities', {
               method: 'GET',
            })
               .then((response) => response.json())
               .then((json) => {
                  this.facilities = json
               })
         },

         getUnitCaseTypes() {
            fetch('/Umbraco/Api/Case/UnitCaseTypes', {
               method: 'GET',
            })
               .then((response) => response.json())
               .then((json) => {
                  this.unitCaseTypes = json
               })
         },

         setMode(type) {
            this.mode = type

            if (type == 'map') {
               if (this.hasMorePages) {
                  this.getNextPage()
               }
               setTimeout(() => {
                  this.generateMap()
               })
            } else {
               this.search.PageIndex = 1
            }
         },

         generateMap() {
            if (this.loading) {
               return
            }

            this.map = new google.maps.Map(
               document.getElementById('rentals-map'),
               mapOptions
            )

            if (window.innerWidth < 984) {
               window.scrollTo({
                  top:
                     document
                        .getElementById('rentals-map')
                        .getBoundingClientRect().top +
                     window.pageYOffset +
                     -55,
                  behavior: 'smooth',
               })
            }

            const latlngbounds = new google.maps.LatLngBounds()

            var oms = new OverlappingMarkerSpiderfier(this.map, {
               markersWontMove: true,
               markersWontHide: true,
               basicFormatEvents: true,
               keepSpiderfied: true,
            })

            this.markers = []

            this.appartments.forEach((appartment) => {
               if (!appartment.Latitude || !appartment.Longitude) {
                  return null
               }

               const latLng = new google.maps.LatLng(
                  appartment.Latitude,
                  appartment.Longitude
               )

               latlngbounds.extend(latLng)

               const marker = new google.maps.Marker({
                  icon: '/dist/img/pin.png',
                  position: latLng,
                  id: appartment.id,
                  title: appartment.store,
                  animation: google.maps.Animation.DROP,
               })

               marker.addListener('spider_click', () => {
                  this.openWindow(appartment, marker)
               })

               this.markers.push(marker)

               oms.addMarker(marker)
            })

            this.map.fitBounds(latlngbounds)

            setTimeout(() => {
               this.map.setOptions({
                  maxZoom: null,
               })
            }, 100)
         },

         generateInfoWindow(appartment) {
            return new google.maps.InfoWindow({
               content: `<a class="case-element case-element--list case-element--map" href="/cases/${
                  appartment.Id
               }">
               <div class="case-element__image ${
                  appartment.State == 'Kontrakt under udarbejdelse'
                     ? 'case-element__image--blurred'
                     : ''
               }">
                  ${
                     appartment.State == 'Kontrakt under udarbejdelse'
                        ? `<div class="case-element__state">Afventer underskrift</div>`
                        : ''
                  }
                   <img loading="lazy" src="${
                      this.getImagesInOrder(appartment.Images)[0].SmallSourceUrl
                   }" alt="${appartment.Title}" width="450" height="300" />
               </div>
           
               <div class="case-element__content">
                   <div class="case-element__tags">
                       <div class="tag">
                           <svg>
                               <use xlink:href="/dist/symbol/svg/sprite.symbol.svg#pin-map"></use>
                           </svg>
           
                           ${appartment.City.Name}
                       </div>
           
                       <div class="tag" v-if="appartment.UnitType">
                           <svg>
                               <use xlink:href="/dist/symbol/svg/sprite.symbol.svg#house-chimney"></use>
                           </svg>
           
                           ${appartment.UnitType}
                       </div>
                   </div>
           
                   <h4 class="case-element__title">${appartment.Title}</h4>
           
                   <div class="case-element__price">
                       ${this.formatPrice(appartment.Rent)} kr.
                   </div>
           
                   <div class="case-element__specs">
                       <div class="case-element__spec">
                           <svg>
                               <use xlink:href="/dist/symbol/svg/sprite.symbol.svg#area"></use>
                           </svg>
                           ${appartment.Size} m²
                       </div>
           
                       <div class="case-element__spec">
                           <svg>
                               <use xlink:href="/dist/symbol/svg/sprite.symbol.svg#rooms"></use>
                           </svg>
                           ${appartment.Rooms} værelser
                       </div>
           
                       <div class="case-element__spec">
                           <svg>
                               <use xlink:href="/dist/symbol/svg/sprite.symbol.svg#floor"></use>
                           </svg>
                           Etage: ${
                              appartment.Floor ? appartment.Floor + '.' : ''
                           }
                       </div>
           
                       <div class="case-element__spec">
                           <svg>
                               <use xlink:href="/dist/symbol/svg/sprite.symbol.svg#calender"></use>
                           </svg>
                           ${this.formatDate(appartment.AcquisitionDate)}
                       </div>
                   </div>
               </div>
           </a>`,
            })
         },

         openWindow(appartment, marker) {
            if (this.infowindow) {
               this.infowindow.close()
            }

            this.infowindow = this.generateInfoWindow(appartment)
            this.infowindow.open(this.map, marker)
         },

         goToLocation(id) {
            window.scrollTo({ top: 0, behavior: 'smooth' })

            const marker = this.markers.find((item) => item.id === id)

            const appartment = this.stores.find((item) => item.id === id)

            this.openWindow(appartment, marker)
            this.map.panTo(marker.position)
            this.map.setZoom(12)
         },

         // CONTACT
         open() {
            document.getElementById("js-general-contact").classList.remove("modal--hidden")
         },
      },
      computed: {
         filteredAreaSuggestions() {
            return this.areas.filter(
               (x) =>
                  !this.areasSelected.find((y) => y.Id == x.Id) &&
                  x.Name.toLowerCase().includes(this.city.toLowerCase())
            )
         },
         filteredCitySuggestions() {
            return this.citySuggestions.filter(
               (x) => !this.citiesSelected.find((y) => y.zip == x.postnummer.nr)
            )
         },
         filteredTagSuggestions() {
            return this.tags.filter((x) =>
               x.Name.toLowerCase().includes(this.city.toLowerCase())
            )
         },
      },
      mounted() {
         this.handleInitialSearch()
         this.getFacilities()
         this.getUnitCaseTypes()

         const triggerTop = this.$refs.triggers.offsetTop - 100

         if(this.$refs.stickyhelp) {
            this.helpHeight = this.$refs.stickyhelp.parentNode.offsetHeight

            window.addEventListener('scroll', () => {
               if (this.$refs.stickyhelp) {
                  const stickHelpTrigger = this.$refs.stickyhelp.offsetTop - 100

                  if (window.scrollY > triggerTop) {
                     this.fixButtons = true
                  } else {
                     this.fixButtons = false
                  }

                  if (window.scrollY > stickHelpTrigger) {
                     this.fixHelp = true
                  } else {
                     this.fixHelp = false
                  }
               }
            })
         }

         const targets = document.getElementsByClassName(
            'js-rentals-city-dropdown'
         )

         document.addEventListener('click', (event) => {
            const path = event.composedPath()
            let isAnyInPath = false

            for (let i = 0; i < targets.length; i++) {
               if (path.includes(targets[i])) {
                  isAnyInPath = true
               }
            }

            if (!isAnyInPath) {
               this.citySuggestionsVisible = false
            }
         })
      },
      watch: {
         search: {
            handler: function (value) {
               console.log(value.PageIndex, this.prevPage)
               if (value.PageIndex == this.prevPage.toString()) {
                  this.search.PageIndex = 1
                  this.prevPage = 1
                  this.debounceSearch()
               } else {
                  this.debounceSearch(true)
               }
            },
            deep: true,
         },
         freeNow: {
            handler: function (value) {
               this.search.AcquisitionDateFrom = null

               if(value) {
                  this.search.AcquisitionDateTo = new Date().toISOString().split('T')[0]
               } else {
                  this.search.AcquisitionDateTo = null;
               }
            },
         },
         citiesSelected: {
            handler: function () {
               this.debounceSearch()
            },
            deep: true,
         },
         loading: {
            handler: function () {
               if (!this.loading && this.mode == 'map') {
                  this.generateMap()
               }
            },
         },
         areasSelected: {
            handler: function () {
               this.debounceSearch()
            },
            deep: true,
         },
         tagsSelected: {
            handler: function () {
               this.debounceSearch()
            },
            deep: true,
         },
      },
   })
}