const scrollButton = document.getElementById('js-scroll-to-top')
if (scrollButton) {
   scrollButton.addEventListener('click', () => {
      window.scrollTo({
         top: 0,
         behavior: 'smooth',
      })
   })

   window.addEventListener('scroll', () => {
      if (window.scrollY > 200) {
         scrollButton.classList.add('scroll-to-top--visible')
      } else {
         scrollButton.classList.remove('scroll-to-top--visible')
      }
   })
}
