const caseSearchElements = document.getElementsByClassName(
   'js-case-search-element'
)

const caseElement = document.getElementById('js-case-view')

if (caseSearchElements.length) {
   for (let i = 0; i < caseSearchElements.length; i++) {
      const caseSearchElement = caseSearchElements[i]
      new Vue({
         el: caseSearchElement,
         data() {
            return {
               loading: false,
               appartments: [],
               areas: [],
            }
         },
         methods: {
            formatPrice(price) {
               return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
            },

            formatDate(date) {
               const dateObj = new Date(date)
               if(dateObj > new Date()) {
                  return dateObj.toLocaleDateString('da-DK', 
                  {
                     year: 'numeric',
                     month: 'long',
                     day: 'numeric',
                  });
               } else {
                  return 'Ledig nu'
               }
            },
            
            getAreas() {
               fetch('/Umbraco/Api/Case/Areas', {
                  method: 'GET',
               })
                  .then((response) => response.json())
                  .then((json) => {
                     this.areas = json

                     this.search()
                  })
            },

            search() {
               this.loading = true

               const area = this.areas.find((area) =>
                  area.Cities.find(
                     (city) =>
                        city.ZipCode ==
                        parseInt(caseSearchElement.getAttribute('data-search'))
                  )
               )

               if (!area) {
                  return
               }

               const searchObj = {
                  ZipCodes: area.Cities.map((x) => x.ZipCode),
               }

               fetch('/Umbraco/Api/Case/Search', {
                  method: 'POST',
                  headers: {
                     'Content-Type': 'application/json',
                  },
                  body: JSON.stringify(searchObj),
               })
                  .then((response) => response.json())
                  .then((json) => {
                     if (caseElement && caseElement.getAttribute('data-id')) {
                        this.appartments = json.Cases.filter(
                           (x) =>
                              x.Id !==
                              parseInt(caseElement.getAttribute('data-id'))
                        )
                     } else {
                        this.appartments = json.Cases
                     }
                  })
                  .catch((e) => {
                     console.log('catch', e)
                  })
                  .finally(() => {
                     setTimeout(() => {
                        this.loading = false
                     }, 200)
                  })
            },

            getImagesInOrder(images) {
               return [...images].sort(
                  (a, b) => a.DisplayOrder - b.DisplayOrder
               )
            },
         },

         mounted() {
            this.getAreas()
         },
      })
   }
}
