const generalContact = document.getElementById('js-general-contact');

if(generalContact) {
   const generalContactClose = document.getElementById("js-general-contact-close");
   generalContactClose.addEventListener("click", function() {
      generalContact.classList.add("modal--hidden");
      document
         .getElementsByTagName('body')[0]
         .classList.remove('modal-open')
   });
}