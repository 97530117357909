const faqButtons = document.getElementsByClassName('js-faq-button')

if (faqButtons.length) {
   for (let i = 0; i < faqButtons.length; i++) {
      const button = faqButtons[i]
      button.addEventListener('click', () => {
         button.parentElement.classList.toggle('faq-view__question--active')
      })
   }
}
