const linkListEditors = document.getElementsByClassName('js-link-list-editor')

if (linkListEditors.length) {
   for (let i = 0; i < linkListEditors.length; i++) {
      const list = linkListEditors[i]

      const listGroup = list.querySelectorAll(
         '.link-list-editor__collapse-links'
      )

      let hasCollapse = false

      for (let x = 0; x < listGroup.length; x++) {
         if (listGroup[x].childElementCount > 10) {
            hasCollapse = true
         }
      }

      const linkListBtn = linkListEditors[i].querySelector(
         '.link-list-editor__show-more'
      )

      if (hasCollapse) {
         linkListBtn.classList.add('link-list-editor__show-more--show')

         linkListBtn.addEventListener('click', () => {
            linkListEditors[i]
               .querySelector('.link-list-editor')
               .classList.add('link-list-editor--show')
            linkListBtn.classList.remove('link-list-editor__show-more--show')
         })
      }
   }
}
