document.addEventListener('DOMContentLoaded', function () {
   const projectElements = document.querySelectorAll('.js-project-element')

   if (projectElements.length) {
      projectElements.forEach((element) => {
         const elementWatcher = scrollMonitor.create(element, -150)

         element
            .querySelector('.js-project-close')
            .addEventListener('click', () => {
               element.classList.remove(
                  'projects-view__project-wrapper--active'
               )
            })

         element
            .querySelector('.projects-view__project')
            .addEventListener('click', () => {
               projectElements.forEach((otherElement) => {
                  otherElement.classList.remove(
                     'projects-view__project-wrapper--active'
                  )
               })

               element.classList.add('projects-view__project-wrapper--active')
            })

         elementWatcher.enterViewport(function () {
            element.classList.remove('projects-view__project-wrapper--top')
            element.classList.remove('projects-view__project-wrapper--bottom')
         })

         elementWatcher.exitViewport(function () {
            if (elementWatcher.isAboveViewport) {
               element.classList.add('projects-view__project-wrapper--top')
            } else if (elementWatcher.isBelowViewport) {
               element.classList.add('projects-view__project-wrapper--bottom')
            }
         })

         if (!elementWatcher.isInViewport) {
            if (elementWatcher.isAboveViewport) {
               element.classList.add('projects-view__project-wrapper--top')
            } else if (elementWatcher.isBelowViewport) {
               element.classList.add('projects-view__project-wrapper--bottom')
            }
         }
      })
   }
})
