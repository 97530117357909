const navigationTrigger = document.getElementById('js-offcanvas-trigger')
const navigationTriggerActiveClass = 'offcanvas__trigger--active'

const offcanvasNavigation = document.getElementById('js-offcanvas')
const offcanvasNavigationActiveClass = 'offcanvas--active'

const offcanvasBackdrop = document.getElementById('js-offcanvas-backdrop')
const offcanvasBackdropActiveClass = 'offcanvas__backdrop--active'

function openOffcanvas() {
   navigationTrigger.classList.add(navigationTriggerActiveClass)
   offcanvasNavigation.classList.add(offcanvasNavigationActiveClass)
   offcanvasBackdrop.classList.add(offcanvasBackdropActiveClass)
}

function closeOffcanvas() {
   navigationTrigger.classList.remove(navigationTriggerActiveClass)
   offcanvasNavigation.classList.remove(offcanvasNavigationActiveClass)
   offcanvasBackdrop.classList.remove(offcanvasBackdropActiveClass)
}

if (offcanvasBackdrop) {
   offcanvasBackdrop.addEventListener('click', () => {
      closeOffcanvas()
   })
}

if (navigationTrigger) {
   navigationTrigger.addEventListener('click', () => {
      if (
         offcanvasNavigation.classList.contains(offcanvasNavigationActiveClass)
      ) {
         closeOffcanvas()
      } else {
         openOffcanvas()
      }
   })
}

let lastScrollPosition = 0
const navigation = document.getElementById('js-navigation')
const isDark = navigation.classList.contains('navigation--dark')
const mobileNavigation = document.getElementById('js-mobile-navigation')
const scrollButton = document.getElementById('js-scroll-to-top')

if (navigation) {
   window.addEventListener('scroll', () => {
      const currentScrollPosition =
         window.pageYOffset || document.documentElement.scrollTop

      if (currentScrollPosition > 50) {
         navigation.classList.add('navigation--focus')

         if (isDark) {
            navigation.classList.remove('navigation--dark')
         }
      } else {
         navigation.classList.remove('navigation--focus')

         if (isDark) {
            navigation.classList.add('navigation--dark')
         }
      }

      if (currentScrollPosition < 200) {
         navigation.classList.remove('navigation--hide')
         return
      }

      if (mobileNavigation) {
         if (currentScrollPosition < lastScrollPosition) {
            navigation.classList.remove('navigation--hide')
            mobileNavigation.classList.remove('mobile-navigation--hide')
            scrollButton.classList.add('scroll-to-top--offset')
         } else {
            mobileNavigation.classList.add('mobile-navigation--hide')
            navigation.classList.add('navigation--hide')
            scrollButton.classList.remove('scroll-to-top--offset')
         }
      }
      lastScrollPosition = currentScrollPosition
   })
}
