document.addEventListener('DOMContentLoaded', function () {
   var tapArea, startX
   tapArea = document.querySelectorAll('.flickity-slider')
   startX = 0
   for (let item of tapArea) {
      item.ontouchstart = function (e) {
         startX = e.touches[0].clientX
      }
      item.ontouchmove = function (e) {
         if (Math.abs(e.touches[0].clientX - startX) > 5 && e.cancelable) {
            e.preventDefault()
         }
      }
   }
})
