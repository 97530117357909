const newsSliders = document.querySelectorAll('.js-news-editor-slider')

if (newsSliders) {
   newsSliders.forEach((slider) => {
      const flkty = new Flickity(slider, {
         cellAlign: 'center',
         wrapAround: true,
         groupCells: false,
         imagesLoaded: false,
         prevNextButtons: true,
         pageDots: true,
         autoPlay: false,
         lazyLoad: true,
      })
   })
}
