const elem = document.querySelector('.js-logos-slider')

if (elem) {
   const flkty = new Flickity(elem, {
      cellAlign: 'left',
      wrapAround: true,
      groupCells: false,
      imagesLoaded: true,
      prevNextButtons: false,
      pageDots: false,
      autoPlay: true,
      lazyLoad: 5,
   })
}
