const descriptionToggle = document.getElementById(
   'js-case-view-description-toggle'
)
const descriptionAbouts = document.getElementById('js-case-abouts')
const descriptionWrapper = document.getElementById(
   'js-case-view-description-wrapper'
)
const description = document.getElementById('js-case-view-description')

if (descriptionAbouts && descriptionToggle && description) {
   if (descriptionAbouts.clientHeight < description.clientHeight) {
      descriptionWrapper.classList.add(
         'case-view__description-wrapper--has-more'
      )
      description.style.maxHeight = descriptionAbouts.clientHeight + 'px'
   } else {
      description.style.minHeight = descriptionAbouts.clientHeight + 'px'
   }

   descriptionToggle.addEventListener('click', () => {
      description.classList.toggle('case-view__description--open')
      descriptionToggle.classList.toggle('case-view__description-toggle--open')
   })
}

const activeSliderClass = 'case-view__image-slider--active'
const activeMapClass = 'case-view__map-wrapper--active'
const isDesktop = window.innerWidth > 985

const initSliders = () => {
   const activeCaseSliders = document.getElementsByClassName(activeSliderClass)

   if (activeCaseSliders.length) {
      for (let i = 0; i < activeCaseSliders.length; i++) {
         const activeCaseSlider = activeCaseSliders[i]

         const flkty = new Flickity(activeCaseSlider, {
            cellAlign: 'left',
            wrapAround: true,
            groupCells: false,
            imagesLoaded: true,
            prevNextButtons: true,
            fullscreen: true,
            pageDots: true,
            autoPlay: true,
         })

         flkty.on('staticClick', () => {
            flkty.viewFullscreen()
         })
      }
   }
}
initSliders()

const caseSliders = document.getElementsByClassName('case-view__image-slider')
const mapWrapper = document.getElementsByClassName('case-view__map-wrapper')
const triggerButton = () => {
   caseSliders[0].classList.remove(activeSliderClass)
   caseSliders[1].classList.remove(activeSliderClass)
   mapWrapper[0].classList.remove(activeMapClass)

   if (!isDesktop) {
      window.scroll({
         top: 80,
         left: 0,
         behavior: 'smooth',
      })
   }
}

const initMap = () => {
   const mapEl = document.getElementById('case-map')
   const map = new google.maps.Map(mapEl, mapOptions)

   const latlngbounds = new google.maps.LatLngBounds()

   const latLng = new google.maps.LatLng(
      parseFloat(mapEl.parentElement.getAttribute('data-lat')),
      parseFloat(mapEl.parentElement.getAttribute('data-lng'))
   )

   latlngbounds.extend(latLng)

   new google.maps.Marker({
      icon: '/dist/img/pin.png',
      position: latLng,
      map,
      title: mapEl.parentElement.getAttribute('data-title'),
      animation: google.maps.Animation.DROP,
   })

   map.fitBounds(latlngbounds)

   setTimeout(() => {
      map.setOptions({
         maxZoom: null,
      })
   }, 100)
}

const imageTrigger = document.getElementById('js-case-images')
if (imageTrigger) {
   imageTrigger.addEventListener('click', () => {
      triggerButton()
      caseSliders[0].classList.add(activeSliderClass)
      initSliders()
   })
}

const planTrigger = document.getElementById('js-case-plans')
if (planTrigger) {
   planTrigger.addEventListener('click', () => {
      triggerButton()
      caseSliders[1].classList.add(activeSliderClass)
      initSliders()
   })
}

const mapsTrigger = document.getElementById('js-case-map')
if (mapsTrigger) {
   mapsTrigger.addEventListener('click', () => {
      triggerButton()
      mapWrapper[0].classList.add(activeMapClass)
      initMap()
   })
}

const contactTriggers = document.getElementsByClassName(
   'js-case-contact-toggle'
)
const caseContact = document.getElementById('js-case-contact')

if (contactTriggers.length) {
   for (let i = 0; i < contactTriggers.length; i++) {
      const trigger = contactTriggers[i]

      trigger.addEventListener('click', () => {
         caseContact.classList.toggle('modal--hidden')
      })
   }
}

// const caseNavigation = document.getElementById('js-case-footer')
// if (caseNavigation) {
//    window.addEventListener('scroll', () => {
//       const currentScrollPosition =
//          window.pageYOffset || document.documentElement.scrollTop

//       if (currentScrollPosition < lastScrollPosition) {
//          caseNavigation.classList.remove('case-view__footer--hide')
//       } else {
//          caseNavigation.classList.add('case-view__footer--hide')
//       }

//       lastScrollPosition = currentScrollPosition
//    })
// }

const caseElement = document.getElementById('js-case-view')
if (caseElement) {
   const caseId = caseElement.getAttribute('data-id')
   if (caseId) {
      fetch('/Umbraco/Api/Case/Track?id=' + caseId + '&type=visited')

      window.localStorage.setItem('prevCaseId', caseId)
   }
}

const casePhoneTrigger = document.getElementById('js-case-phone-trigger')
if (casePhoneTrigger) {
   const caseId = caseElement.getAttribute('data-id')

   casePhoneTrigger.addEventListener('click', () => {
      fetch('/Umbraco/Api/Case/Track?id=' + caseId + '&type=inquired')
   })
}
