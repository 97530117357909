const elem = document.querySelector('.js-testimonials-slider')

if (elem) {
   const flkty = new Flickity(elem, {
      cellAlign: 'center',
      wrapAround: true,
      groupCells: false,
      imagesLoaded: false,
      prevNextButtons: true,
      pageDots: false,
      autoPlay: false,
   })
}
